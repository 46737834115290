const CALLBACK_NAME:string = 'gmapsCallback';

let initialized:boolean = false;
let resolveInitPromise:any;
let rejectInitPromise:any;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export function init(apiKey:string, languageCode?:string) {
    if((window as any).google) { initialized = true; }
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise;

  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  (window as any)[CALLBACK_NAME] = () => {
    initialized = true;
    resolveInitPromise((window as any).google);
  }

  var languageParam = "";
  if(languageCode) {
    languageParam = "&language="+languageCode
  }

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${CALLBACK_NAME}`+languageParam;
  script.onerror = rejectInitPromise;

  // Append the 'script' element to 'head'
  document.head.appendChild(script);

  return initPromise;
}

/**
 * createMap
 * This methods create the map at the element selected with the apiKey provided
 * @param element 
 * @param apiKey - apiKey provided which can be setup in Google Console within Credentials section in which one key can be used for several domains.
 * @param options 
 * @returns 
 */
export function createMap(element:HTMLElement, apiKey:string, options?:google.maps.MapOptions):Promise<google.maps.Map> {
    if(!initialized) {
        return init(apiKey).then(() => {
            return new google.maps.Map(element, options);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
    }
    else {
        return Promise.resolve(new google.maps.Map(element, options));
    }
}