



<template>

    <Section :options="{section:false, container:false}" id="about" class="pb-0 m-0">

          <div class="row">

              <div class="col-md-auto details col-padding">

                  <h3 class="font-body fw-normal ls1">Nous trouver</h3>

                  <div class="contact">
                      <address>
                          <strong>{{$cms_site.company.address.name ? $cms_site.company.address.name : $cms_site.company.name}}</strong><br>
                          {{$cms_site.company.address.addressLines[0]}}<br>
                          {{$cms_site.company.address.postalCode}} {{$cms_site.company.address.city}}<br>
                          <span v-if="$cms_site.company.address.note">{{ $cms_site.company.address.note }}</span>
                      </address>
                      <div>
                          <span v-if="$cms_site.company.phones"><strong>Téléphone: </strong> {{$cms_site.company.phones[0].formatted}}<br/></span>
                          <span v-if="$cms_site.company.fax"><strong>Fax: </strong> {{$cms_site.company.fax.formatted}}<br/></span>
                          <span v-if="$cms_site.company.emails"><strong>E-Mail: </strong> {{$cms_site.company.emails[0].email}}<br/></span>
                          <span v-if="$cms_site.company.web"><strong>Web: </strong> <a :href="$cms_site.company.web" target="_blank">{{$cms_site.company.web}}</a></span>
                      </div>
                      <div v-if="$cms_site.company.contacts" class="contacts">
                        <div v-for="(contact, index) in $cms_site.company.contacts" :key="index + '-contact'">
                          <strong>{{ contact.firstName }} {{ contact.lastName }}</strong><br/>
                          <span v-if="contact.phones"><strong>Téléphone: </strong> {{contact.phones[0].formatted}}<br/></span>
                          <span v-if="contact.emails"><strong>E-Mail: </strong> {{contact.emails[0].email}}<br/></span>
                        </div>
                      </div> 
                  </div>

                  <div v-if="$cms_site.company.socialMedias" class="social-medias clearfix">
                  <a v-if="$cms_site.company.socialMedias.facebook" :href="'https://www.facebook.com/'+$cms_site.company.socialMedias.facebook" target="_blank" class="social-icon  si-small si-text-color si-facebook">
                      <i class="icon-facebook"></i>
                      <i class="icon-facebook"></i>
                  </a>
                  <a v-if="$cms_site.company.socialMedias.instagram" :href="'https://www.instagram.com/'+$cms_site.company.socialMedias.instagram" target="_blank" class="social-icon si-small si-text-color si-instagram">
                      <i class="icon-instagram"></i>
                      <i class="icon-instagram"></i>
                  </a>
                  </div>

                  <div v-if="$cms_site.company.identifiers" class="identifiers clearfix">
                    <span v-if="$cms_site.company.identifiers && $cms_site.company.identifiers.siret">
                        <strong>{{$t('shop.shop.info.identifiers.siret')}}</strong> {{$cms_site.company.identifiers.siret}}<br/>
                    </span>
                    <span v-if="$cms_site.company.vatNumber">
                        <strong>{{$t('shop.shop.info.vatNumber')}}</strong> {{$cms_site.company.vatNumber}}<br/>
                    </span>
                  </div>

              </div>

              <div class="col-md map-container">
                  <div class="map" ref="map"></div>
              </div>

          </div>

    </Section>
    
</template>

<style>


#about .row:first-child {
  margin-top:0px;
}

#about .row {
  margin-top:40px;
}

#about .map {
  min-height:200px;
  height:100%;
}

#about .details .contact address {
  margin-bottom:20px;
}

#about .details .contact address span {
  display: inline-block;
  padding-top: 5px;
}

#about .details .contact .contacts {
  margin-top:30px;
}

#about .details .contact .contacts > div {
  margin-top:10px;
}

@media (max-width: 767.98px) {
  #about .details {
    padding:3rem;
  }

  #about .details .contact,
  #about .details .contact .contacts {
    display: flex;
    flex-wrap: wrap;
  }

  #about .details .contact address,
  #about .details .contact > div,
  #about .details .contact .contacts > div {
    flex: 1 0 auto;
    margin-right:30px;
  }

  #about .details .contact .contacts {
    flex:auto;
    margin-right:0px;
    margin-top:20px;
  }

  #about .map {
    margin-top:20px;
  }
}

#about .identifiers, #about .social-medias {
  margin-top:20px;
}
    

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as gmap from '@fwk-client/utils/gmap';
import { getPath } from '@igotweb-node-api-utils/misc';
import Section from '@root/src/client/components/panels/layouts/canvas/Section.vue';

@Component({
  components: { 
      Section
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class About extends Vue {


  mounted() {
    // We check if we can show the map
    if(this.$cms_site && this.$cms_site.company && 
        this.$cms_site.company.address && 
        this.$cms_site.company.address.lat && 
        this.$cms_site.company.address.lng) {
      this.showMap();
    }
  }

  showMap() {
    // We need to get the apiKey
    var apiKey = getPath(this.$store.state, "server.configuration.fwk.map.gmap.id");
    // We create the map
    gmap.createMap(this.$refs.map as HTMLElement, apiKey, {
			mapTypeId: "roadmap",
			zoom: 17,
      center: {lat:this.$cms_site.company.address.lat, lng:this.$cms_site.company.address.lng},
      styles : [
        // We hide other business point of interests
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        }
      ],
    })
    .then((map) => {
      var marker = new google.maps.Marker({
          position: {lat:this.$cms_site.company.address.lat, lng:this.$cms_site.company.address.lng},
          label : {
            text:this.$cms_site.name
          },
          animation: google.maps.Animation.DROP,
          map: map,
          icon : {
            url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
            size: new google.maps.Size(27, 43),
            scaledSize: new google.maps.Size(27, 43),
            anchor:new google.maps.Point(14, 43),
            labelOrigin: new google.maps.Point(14, 43+10) // Label origin is center of the text
          }
        })
      var info = new google.maps.InfoWindow({
        content: '<div style="width: 300px;"><h4 style="margin-bottom: 8px;">Hi, we\'re <span>Envato</span></h4><p class="mb-0">Our mission is to help people to <strong>earn</strong> and to <strong>learn</strong> online. We operate <strong>marketplaces</strong> where hundreds of thousands of people buy and sell digital goods every day, and a network of educational blogs where millions learn <strong>creative skills</strong>.</p></div>',
        position: {lat:this.$cms_site.company.address.lat, lng:this.$cms_site.company.address.lng},
      })
      marker.addListener('click', function() {
        console.log("gmap marker click")
        // info.open(map);
      })
    })
    .catch((error) => {
      console.log(error);
    });
  }
    
}
</script>